import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  of,
} from 'rxjs';
import { Notification } from './notification.model';
import { FirebaseApiProvider } from 'libs/api/providers/firebase-api.provider';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { UnsubscriberService } from 'libs/unsubcriber/unsubscriber.service';
import { MessageService } from 'libs/message/message.service';
import { UserService } from 'libs/user/user.service';
import { PushNotificationService } from 'apps/craf2s-client-app/src/app/services/push-notification/push-notification.service';
import { MessageGroup } from 'libs/message/messageGroup.model';

export type NotificationCountsByMessageGroup = {
  [messageGroupId: string]: number;
};

export type InvolvedUserNotificationCounts = {
  directMessages: number;
  tripartiteMessages: {
    [messageGroupId: string]: number;
  };
  results: number;
  evaluations: number;
  evaluationsByTutor: { [tutorId: string]: number };
  total: number;
};

export type NotificationsCountByInvolvedUser = {
  [userId: string]: InvolvedUserNotificationCounts;
};

export type MessageNotifications = {
  notifications: Notification[];
  count: number;
};

export type UserNotifications = {
  [trainingId: string]: {
    messages: MessageNotifications;
    evaluations?: {
      notifications: Notification[];
      count: number;
    };
    apprenticeResults?: {
      notifications: Notification[];
      count: number;
    };
    tutorResults?: {
      notifications: Notification[];
      count: number;
    };
    messageGroups?: NotificationCountsByMessageGroup;
    involvedUsers?: NotificationsCountByInvolvedUser;
    menuNotificationsCount?: number;
    count?: number;
  };
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private isListeningToUserNotifications = false;
  private notificationsSubject: BehaviorSubject<Notification[]> =
    new BehaviorSubject([]);
  notifications$: Observable<Notification[]> =
    this.notificationsSubject.asObservable();
  private messageGroups$: Observable<MessageGroup[]>;

  constructor(
    private apiService: FirebaseApiProvider,
    private userService: UserService,
    private messageService: MessageService,
    private pushNotificationService: PushNotificationService,
    private unsubscriberService: UnsubscriberService
  ) {}

  getUserNotifications(
    userId: string,
    role: string
  ): Observable<UserNotifications> {
    if (!this.isListeningToUserNotifications) {
      console.log('getUserNotifications API CALL LISTENING:', role);
      this.isListeningToUserNotifications = true;
      this.listenToUserNotificationChanges(userId);
      this.messageGroups$ = this.messageService.getUserMessageGroups(role);
    } else {
      console.log('getUserNotifications FROM CACHE:', role);
    }

    return this.combineNotificationsWithMessageGroupsAndUsers(
      this.messageGroups$
    ).pipe(
      tap((notifications) =>
        this.pushNotificationService.setBadgeNumber(notifications.length)
      ),
      map((notifications) =>
        notifications.reduce((userNotifications, notification) => {
          if (!userNotifications[notification.trainingId]) {
            // Case alumni notification
            if (
              notification.trainingId === null &&
              !userNotifications['alumni']
            ) {
              userNotifications['alumni'] = {
                messages: {
                  notifications: [],
                  count: 0,
                },
              };
            } else {
              userNotifications[notification.trainingId] = {
                messages: {
                  notifications: [],
                  count: 0,
                },
                evaluations: {
                  notifications: [],
                  count: 0,
                },
                apprenticeResults: {
                  notifications: [],
                  count: 0,
                },
                tutorResults: {
                  notifications: [],
                  count: 0,
                },
                messageGroups: {},
                involvedUsers: {},
                menuNotificationsCount: 0,
                count: 0,
              };
            }
          }

          if (notification.trainingId === null) {
            const notifications = userNotifications['alumni'];
            notifications.messages.notifications.push(notification);
            notifications.messages.count++;
          } else {
            const notifications = userNotifications[notification.trainingId];
            const messageGroupsNotifications =
              userNotifications[notification.trainingId].messageGroups;
            const involvedUserNotifications =
              userNotifications[notification.trainingId].involvedUsers;

            if (notification.involvedUserId) {
              if (!involvedUserNotifications[notification.involvedUserId]) {
                involvedUserNotifications[notification.involvedUserId] = {
                  directMessages: 0,
                  tripartiteMessages: {},
                  results: 0,
                  evaluations: 0,
                  evaluationsByTutor: {},
                  total: 0,
                };
              }
              if (
                !involvedUserNotifications[notification.involvedUserId]
                  .tripartiteMessages[notification.messageGroupId] &&
                notification.messageGroupId !== undefined
              ) {
                involvedUserNotifications[
                  notification.involvedUserId
                ].tripartiteMessages[notification.messageGroupId] = 0;
              }
            }

            switch (notification.type) {
              case 'message':
                notifications.messages.notifications.push(notification);
                notifications.messages.count++;
                if (notification.messageGroup) {
                  if (!notification.messageGroup.singleRoles) {
                    if (
                      !messageGroupsNotifications[notification.messageGroup.id]
                    ) {
                      messageGroupsNotifications[
                        notification.messageGroup.id
                      ] = 0;
                    }
                    messageGroupsNotifications[notification.messageGroup.id]++;
                  } else if (
                    JSON.stringify(notification.messageGroup?.singleRoles) ===
                    JSON.stringify(['apprentice', 'tutor'])
                  ) {
                    involvedUserNotifications[notification.involvedUserId]
                      .tripartiteMessages[notification.messageGroupId]++;
                    involvedUserNotifications[notification.involvedUserId]
                      .total++;
                  }
                } else {
                  involvedUserNotifications[notification.involvedUserId]
                    .directMessages++;
                  involvedUserNotifications[notification.involvedUserId]
                    .total++;
                  if (
                    role === 'tutor' &&
                    notification.involvedUser?.role === 'apprentice'
                  ) {
                    notifications.menuNotificationsCount++;
                  }
                }
                break;
              case 'evaluation':
                notifications.evaluations.notifications.push(notification);
                notifications.evaluations.count++;
                notifications.menuNotificationsCount++;
                involvedUserNotifications[notification.involvedUserId]
                  .evaluations++;
                involvedUserNotifications[notification.involvedUserId].total++;
                const tutorId = notification.url.split('/').pop();
                if (
                  !involvedUserNotifications[notification.involvedUserId]
                    .evaluationsByTutor[tutorId]
                ) {
                  involvedUserNotifications[
                    notification.involvedUserId
                  ].evaluationsByTutor[tutorId] = 0;
                }
                involvedUserNotifications[notification.involvedUserId]
                  .evaluationsByTutor[tutorId]++;
                break;
              case 'apprenticeResult':
                notifications.apprenticeResults.notifications.push(
                  notification
                );
                notifications.apprenticeResults.count++;
                notifications.menuNotificationsCount++;
                involvedUserNotifications[notification.involvedUserId]
                  .results++;
                involvedUserNotifications[notification.involvedUserId].total++;
                break;
              case 'tutorResult':
                notifications.tutorResults.notifications.push(notification);
                notifications.tutorResults.count++;
                notifications.menuNotificationsCount++;
                involvedUserNotifications[notification.involvedUserId]
                  .results++;
                involvedUserNotifications[notification.involvedUserId].total++;
                break;
            }

            notifications.count++;
          }

          return userNotifications;
        }, {} as UserNotifications)
      ),
      tap((userNotifications) =>
        console.log('USER NOTIFICATIONS:', userNotifications)
      )
    );
  }

  async createNotifications(notifications: Notification[]): Promise<void> {
    try {
      await this.apiService.createBatch<Notification>(
        'notifications',
        notifications,
        Notification.toObject
      );
    } catch (e) {
      throw e;
    }
  }

  async deleteMessageNotifications(messageId: string): Promise<void> {
    try {
      const notifications = await this.getMessageNotifications(messageId);
      console.log('DELETE MESSAGE NOTIFICATIONS:', notifications);
      if (notifications.length > 0) {
        await this.deleteNotifications(notifications);
      }
    } catch (e) {
      throw e;
    }
  }

  async getMessagesNotifications(
    userId: string,
    role: string,
    trainingId: string,
    messageIds: string[]
  ): Promise<Notification[]> {
    return lastValueFrom(
      this.getUserNotifications(userId, role).pipe(
        take(1),
        map((userNotifications) =>
          userNotifications[trainingId]?.messages.notifications.filter(
            (notification) =>
              messageIds.includes(notification.messageId) &&
              !notification?.pinned
          )
        )
      )
    );
  }

  async deleteMessagesNotifications(notificationIds: string[]): Promise<void> {
    try {
      await this.apiService.deleteBatch('notifications', notificationIds);
    } catch (e) {
      throw e;
    }
  }

  private listenToUserNotificationChanges(userId: string) {
    if (this.notificationsSubject.getValue()) {
      this.notificationsSubject.next([]);
    }

    const notificationsSubscribe = this.apiService
      .listenToChanges<Notification>(
        'notifications',
        Notification.fromObject,
        [
          {
            fieldPath: 'userId',
            condition: '==',
            value: userId,
          },
        ],
        'date',
        'desc'
      )
      .pipe(
        finalize(() => {
          this.isListeningToUserNotifications = false;
          console.log('listenToUserNotificationChanges finalized.');
        })
      )
      .subscribe((notifications) => {
        console.log('listenToUserNotificationChanges NEXT:', notifications);
        this.notificationsSubject.next(notifications);
      });

    this.unsubscriberService.add(
      'NotificationService:listenToUserNotificationChanges',
      notificationsSubscribe
    );
  }

  private combineNotificationsWithMessageGroupsAndUsers(
    messageGroups$: Observable<MessageGroup[]>
  ): Observable<Notification[]> {
    return this.notifications$.pipe(
      switchMap((notifications) => {
        if (notifications.length === 0) {
          return combineLatest([this.notifications$, of([]), of([])]);
        }

        const involvedUserIds = Array.from(
          new Set(
            notifications.map((notification) => notification.involvedUserId)
          )
        );
        const involvedUsers$ = this.userService.getUsers(involvedUserIds);
        return combineLatest([
          this.notifications$,
          messageGroups$,
          involvedUsers$,
        ]);
      }),
      map(([notifications, messageGroups, involvedUsers]) => {
        if (notifications.length > 0) {
          notifications = notifications.map((notification) => {
            if (notification.messageGroupId) {
              // Split '/' is to extract tripartite messageGroupId
              notification.messageGroup = messageGroups.find(
                (messageGroup) =>
                  messageGroup.id === notification.messageGroupId.split('/')[0]
              );
            }

            if (notification.involvedUserId) {
              notification.involvedUser = involvedUsers.find(
                (involvedUser) =>
                  involvedUser.id === notification.involvedUserId
              );
            }

            return notification;
          });
        }

        return notifications;
      })
    );
  }

  private getMessageNotifications(messageId: string): Promise<Notification[]> {
    try {
      return this.apiService.fetchAll<Notification>(
        'notifications',
        Notification.fromObject,
        [
          {
            fieldPath: 'messageId',
            condition: '==',
            value: messageId,
          },
        ]
      );
    } catch (e) {
      console.error('getMessageNotifications', e);
      console.error('messageId: ', messageId);
      throw e;
    }
  }

  private async deleteNotifications(
    notifications: Notification[]
  ): Promise<void> {
    try {
      const ids = Array.from(new Set(notifications.map((n) => n.id)));
      console.log('Delete Notifications: ', ids);
      await this.apiService.deleteBatch('notifications', ids);
    } catch (e) {
      throw e;
    }
  }

  async deleteAllTrainingNotifications(trainingId: string) {
    try {
      const trainingNotifications =
        await this.apiService.fetchAll<Notification>(
          'notifications',
          Notification.fromObject,
          [
            {
              fieldPath: 'trainingId',
              condition: '==',
              value: trainingId,
            },
          ]
        );

      console.log('Notifications to delete:', trainingNotifications);
      await this.deleteNotifications(trainingNotifications);
    } catch (e) {
      throw e;
    }
  }

  async deleteTrainingUserNotifications(userId: string, trainingId: string) {
    try {
      const userNotifications = await this.apiService.fetchAll<Notification>(
        'notifications',
        Notification.fromObject,
        [
          {
            fieldPath: 'userId',
            condition: '==',
            value: userId,
          },
          {
            fieldPath: 'trainingId',
            condition: '==',
            value: trainingId,
          },
        ]
      );

      const involvedUserNotifications =
        await this.apiService.fetchAll<Notification>(
          'notifications',
          Notification.fromObject,
          [
            {
              fieldPath: 'involvedUserId',
              condition: '==',
              value: userId,
            },
            {
              fieldPath: 'trainingId',
              condition: '==',
              value: trainingId,
            },
          ]
        );

      const notificationsToDelete = [
        ...(userNotifications ?? []),
        ...(involvedUserNotifications ?? []),
      ];

      console.log('Notifications to delete:', notificationsToDelete);
      await this.deleteNotifications(notificationsToDelete);
    } catch (e) {
      throw e;
    }
  }

  async deleteTutorApprenticeNotifications(
    tutorId: string,
    apprenticeId: string,
    trainingId: string,
    noOtherApprentices: boolean
  ): Promise<void> {
    try {
      const tripartieId = await firstValueFrom(
        this.messageService.getUserMessageGroups('tutor').pipe(
          tap((mgs) => console.log(mgs)),
          map(
            (messageGroups) =>
              messageGroups.filter(
                (messageGroup) =>
                  JSON.stringify(messageGroup.singleRoles) ===
                  JSON.stringify(['apprentice', 'tutor'])
              )[0].id
          ),
          tap((id) => console.log(id))
        )
      );

      const tripartiteMessageGroupId = `${tripartieId}/${apprenticeId}/${tutorId}`;

      const tripartiteNotifications =
        await this.apiService.fetchAll<Notification>(
          'notifications',
          Notification.fromObject,
          [
            {
              fieldPath: 'messageGroupId',
              condition: '==',
              value: tripartiteMessageGroupId,
            },
            {
              fieldPath: 'trainingId',
              condition: '==',
              value: trainingId,
            },
          ]
        );

      const tutorApprenticeNotifications =
        await this.apiService.fetchAll<Notification>(
          'notifications',
          Notification.fromObject,
          [
            {
              fieldPath: 'userId',
              condition: 'in',
              value: [tutorId, apprenticeId],
            },
            {
              fieldPath: 'involvedUserId',
              condition: 'in',
              value: [tutorId, apprenticeId],
            },
            {
              fieldPath: 'trainingId',
              condition: '==',
              value: trainingId,
            },
          ]
        );

      const notificationsToDelete = [
        ...(tripartiteNotifications ?? []),
        ...(tutorApprenticeNotifications ?? []),
      ];

      if (noOtherApprentices) {
        const messageGroupsNotifications =
          await this.apiService.fetchAll<Notification>(
            'notifications',
            Notification.fromObject,
            [
              {
                fieldPath: 'messageGroupId',
                condition: '!=',
                value: null,
              },
              {
                fieldPath: 'involvedUserId',
                condition: '==',
                value: tutorId,
              },
              {
                fieldPath: 'trainingId',
                condition: '==',
                value: trainingId,
              },
            ]
          );

        notificationsToDelete.push(...(messageGroupsNotifications ?? []));
      }

      console.log('Notifications to delete:', notificationsToDelete);
      await this.deleteNotifications(notificationsToDelete);
    } catch (e) {
      throw e;
    }
  }

  updateNotification(notification: Notification): Promise<void> {
    return this.apiService.update<Notification>(
      `notifications/${notification.id}`,
      notification,
      Notification.toObject
    );
  }
}
