import { User } from 'libs/user/user.model';

export type MessageGroupType = {
  title: string;
  roles: string[];
  background: string;
  color: string;
  singleRoles: string[];
  id?: string;
  users?: User[];
};

export class MessageGroup {
  constructor(
    public title: string,
    public roles: string[],
    public background: string,
    public color: string,
    public singleRoles: string[],
    public id?: string,
    public users?: User[]
  ) {}

  static fromObject(data: any): MessageGroup {
    return new MessageGroup(
      data?.title,
      data?.roles,
      data?.background,
      data?.color,
      data?.singleRoles,
      data?.id
    );
  }

  static toObject(messageGroup: MessageGroup): any {
    const data = {
      title: messageGroup.title,
      roles: messageGroup.roles,
      background: messageGroup.background,
      color: messageGroup.color,
    };

    if (messageGroup.singleRoles) {
      data['singleRoles'] = messageGroup.singleRoles;
    }

    if (messageGroup.id) {
      data['id'] = messageGroup.id;
    }

    return data;
  }

  getInitials(): string {
    return this.title
      .split(' ')
      .map((word) => word[0])
      .join('');
  }
}
