import { MessageGroup } from 'libs/message/messageGroup.model';
import { User } from 'libs/user/user.model';

export class Notification {
  constructor(
    public userId: string,
    public trainingId: string,
    public type: string,
    public involvedUserId: string,
    public title: string,
    public body: string,
    public url: string,
    public date: string,
    public messageId?: string,
    public messageGroupId?: string,
    public questionnaireId?: string,
    public id?: string,
    public pinned?: boolean,

    public involvedUser?: User,
    public messageGroup?: MessageGroup
  ) {}

  static fromObject(data: any): Notification {
    return new Notification(
      data?.userId,
      data?.trainingId,
      data?.type,
      data?.involvedUserId,
      data?.title,
      data?.body,
      data?.url,
      data?.date,
      data?.messageId,
      data?.messageGroupId,
      data?.questionnaireId,
      data?.id,
      data?.pinned
    );
  }

  static toObject(notification: Notification): any {
    const data = {
      userId: notification.userId,
      trainingId: notification.trainingId,
      type: notification.type,
      involvedUserId: notification.involvedUserId,
      title: notification.title,
      body: notification.body,
      url: notification.url,
      date: notification.date,
    };

    if (notification.messageId) {
      data['messageId'] = notification.messageId;
    }

    if (notification.messageGroupId) {
      data['messageGroupId'] = notification.messageGroupId;
    }

    if (
      notification.hasOwnProperty('pinned') &&
      notification.pinned !== undefined
    ) {
      data['pinned'] = notification.pinned;
    }

    if (notification.questionnaireId) {
      data['questionnaireId'] = notification.questionnaireId;
    }

    return data;
  }
}
