import {
  Component,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GlobalService } from 'libs/global/global.service';
import { User } from 'libs/user/user.model';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Observable, filter, map, tap } from 'rxjs';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { environment } from '../environments/environment';
import { UserService } from 'libs/user/user.service';
import { UnsubscriberService } from 'libs/unsubcriber/unsubscriber.service';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  currentUser$: Observable<User | null> = this.userService.currentUser$.pipe(
    tap((user) => {
      if (user) {
        this.userService.initFcm(user);
        if (this.currentUserRole && this.currentUserRole !== user.role) {
          console.log('CURRENT USER ROLE HAS CHANGED!', user.role);
          // this.unsubscriberService.unsubscribeAll([
          //   'UserService:listenToCurrentUser',
          //   'UserService:fcmToken',
          //   'NotificationService:listenToUserNotificationChanges',
          // ]);
          this.router.navigateByUrl(`${user.role}/home`, { replaceUrl: true });
        }
        this.currentUserRole = user.role;
      }
    })
  );
  currentUserRole = '';

  constructor(
    private zone: NgZone,
    private platform: Platform,
    private router: Router,
    private userService: UserService,
    private globalService: GlobalService,
    private unsubscriberService: UnsubscriberService
  ) {
    this.platform.ready().then(() => {
      this.globalService.customStatusbar();
      SplashScreen.hide();
    });
    this.listenToAppUrlOpen();
  }

  // FIRED WHEN CLICKING ON MAILTO OR TEL LINKS !!
  // @HostListener('window:beforeunload', ['$event'])
  // unloadHandler(event: Event): boolean {
  //   if (Capacitor.getPlatform() === 'web') {
  //     const eventTargetDoc = event.target as Document;
  //     if (!eventTargetDoc.activeElement['href']) {
  //       this.unsubscriberService.unsubscribeAll();
  //     }
  //   }

  //   return false;
  // }

  async ngOnInit() {
    await this.globalService.checkIfUpdateIsAvailable();

    this.globalService.disableLogOnProd(environment.production);
    // this.listenToAppUrlOpen();

    // this.currentUser$.pipe(
    //   filter<User>(Boolean),
    //   map((user) => this.userService.initFcm(user)),
    //   tap((user) => {
    //     if (this.currentUserRole && this.currentUserRole !== user.role) {
    //       this.curr
    //     }
    //   })
    // );
  }

  ngOnDestroy() {
    this.unsubscriberService.unsubscribeAll();
  }

  listenToAppUrlOpen() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(async () => {
        console.log('appUrlOpen: ', event.url);
        const url = new URL(event.url);
        const link = url.searchParams.get('link')
          ? new URL(url.searchParams.get('link'))
          : url;
        console.log('appUrlOpen link decoded: ', link.pathname + link.search);
        window.location.replace(link.pathname + link.search);
      });
    });
  }

  async logout() {
    await this.userService.logout();
  }
}
