import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  NotificationsCountByInvolvedUser,
  NotificationService,
} from 'libs/notification/notification.service';
import { TrainingService } from 'libs/training/training.service';
import { User } from 'libs/user/user.model';

import {
  Observable,
  Subject,
  combineLatest,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

type ApprenticeMenuData = {
  trainingId: string;
  notifications: NotificationsCountByInvolvedUser;
  apprenticeTutors: User[];
};

@Component({
  selector: 'app-apprentice-menu',
  templateUrl: './apprentice-menu.component.html',
  styleUrls: ['./apprentice-menu.component.scss'],
})
export class ApprenticeMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() apprentice: User;
  @Output() logout: EventEmitter<any> = new EventEmitter();
  data$: Observable<ApprenticeMenuData>;

  private destroy$ = new Subject<void>();

  constructor(
    private notificationService: NotificationService,
    private trainingService: TrainingService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      JSON.stringify(changes.tutor?.currentValue?.trainings) !==
      JSON.stringify(changes.tutor?.previousValue?.trainings)
    ) {
      // console.log('APPRENTICE MENU:', changes.apprentice?.currentValue);
      this.getData();
    }
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    console.log('APPRENTICE MENU DESTROY!');
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData() {
    const trainingId$ = this.trainingService
      .getUserActiveTrainings(this.apprentice?.trainings)
      .pipe(map((trainings) => trainings[0].id));

    const apprenticeTutors$ = trainingId$.pipe(
      tap((trainingId) => console.log('MENU TRAINING ID:', trainingId)),
      switchMap((trainingId) =>
        trainingId
          ? this.trainingService.getUserTrainingById(trainingId).pipe(
              map((training) =>
                training.apprentices
                  .find((app) => app.id === this.apprentice.id)
                  .tutors.map((tutorId) => training.users.tutors[tutorId])
              ),
              tap((apprenticeTutors) =>
                console.log('---- APPRENTICE TUTORS ----', apprenticeTutors)
              ),
              map((apprenticeTutors) =>
                apprenticeTutors.filter((tutor) => tutor !== undefined)
              ),
              startWith(null)
            )
          : of(null)
      )
    );

    const notififications$ = trainingId$.pipe(
      switchMap((trainingId) =>
        this.notificationService
          .getUserNotifications(this.apprentice.id, this.apprentice.role)
          .pipe(
            map(
              (userNotifications) =>
                userNotifications[trainingId]?.involvedUsers
            ),
            takeUntil(this.destroy$),
            startWith({})
          )
      )
    );

    this.data$ = combineLatest([
      apprenticeTutors$,
      notififications$,
      trainingId$,
    ]).pipe(
      map(([apprenticeTutors, notifications, trainingId]) => ({
        apprenticeTutors,
        trainingId,
        notifications,
      }))
    );
  }

  handleLogout() {
    this.logout.emit();
  }
}
