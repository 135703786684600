import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  NotificationService,
  NotificationsCountByInvolvedUser,
} from 'libs/notification/notification.service';
import { TrainingService } from 'libs/training/training.service';
import { User } from 'libs/user/user.model';
import {
  Observable,
  Subject,
  combineLatest,
  filter,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

type TutorMenuData = {
  trainingId: string;
  apprentices: User[];
  notifications: NotificationsCountByInvolvedUser;
  trainingsCount: number;
};

@Component({
  selector: 'app-tutor-menu',
  templateUrl: './tutor-menu.component.html',
  styleUrls: ['./tutor-menu.component.scss'],
})
export class TutorMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tutor: User;
  @Output() logout: EventEmitter<any> = new EventEmitter();
  data$: Observable<TutorMenuData>;
  trainingId$: Observable<string>;

  private destroy$ = new Subject<void>();

  constructor(
    private notificationService: NotificationService,
    private trainingService: TrainingService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      JSON.stringify(changes.tutor?.currentValue?.trainings) !==
      JSON.stringify(changes.tutor?.previousValue?.trainings)
    ) {
      console.log('TUTOR MENU:', changes.tutor?.currentValue);
      this.getData();
    }
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    console.log('TUTOR MENU DESTROY!');
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData() {
    const trainingId$ = this.trainingService.currentTrainingId$;

    const apprentices$ = trainingId$.pipe(
      tap((trainingId) => console.log('MENU TRAINING ID:', trainingId)),
      switchMap((trainingId) =>
        trainingId
          ? this.trainingService.getUserTrainingById(trainingId).pipe(
              filter(
                (training) => Object.keys(training.users.apprentices).length > 0
              ),
              map((training) => {
                const tutorApprenticeIds = training.apprentices
                  .filter((apprentice) =>
                    apprentice.tutors.includes(this.tutor.id)
                  )
                  .map((apprentice) => apprentice.id);
                return tutorApprenticeIds.map((apprenticeId) => {
                  const apprentice = training.users.apprentices[apprenticeId];
                  apprentice.tutors = training.apprentices
                    .find((app) => app.id === apprenticeId)
                    .tutors.map((tutorId) => training.users.tutors[tutorId]);
                  return apprentice;
                });
              }),
              startWith(null)
            )
          : of(null)
      )
    );

    const notifications$ = trainingId$.pipe(
      switchMap((trainingId) =>
        this.notificationService
          .getUserNotifications(this.tutor.id, this.tutor.role)
          .pipe(
            map((userNotifications) => {
              console.log('MENU NOTIFICATIONS:', userNotifications);
              return userNotifications[trainingId]?.involvedUsers;
            }),
            takeUntil(this.destroy$),
            startWith({}),
            shareReplay()
          )
      )
    );

    this.data$ = combineLatest([
      apprentices$,
      notifications$,
      trainingId$,
    ]).pipe(
      map(([apprentices, notifications, trainingId]) => ({
        trainingId,
        apprentices,
        notifications,
        trainingsCount: this.tutor.trainings.length,
      }))
    );
  }

  unsetCurrentTrainingId() {
    console.log('UNSET CURRENT TRAINING ID');
    this.trainingService.setCurrentTrainingId(null);
  }

  handleLogout() {
    this.logout.emit();
  }
}
