import { User } from 'libs/user/user.model';
import { MessageGroup } from './messageGroup.model';

export type MessageType = {
  authorId: string;
  message: string;
  date: string;
  trainingId: string;
  trainingCenterId: string;
  messageGroupId?: string;
  toUserId?: string;
  media?: string;
  directUserIds?: string[];
  readBy?: string[];
  id?: string;
};

export class Message {
  constructor(
    public authorId: string,
    public message: string,
    public date: string,
    public trainingId: string,
    public trainingCenterId: string,
    public messageGroupId?: string,
    public toUserId?: string,
    public media?: string,
    public directUserIds?: string[],
    public readBy?: string[],
    public id?: string,
    public author?: User,
    public messageGroup?: MessageGroup
  ) {}

  static fromObject(data: any): Message {
    return new Message(
      data?.authorId,
      data?.message,
      data?.date,
      data?.trainingId,
      data?.trainingCenterId,
      data?.messageGroupId,
      data?.toUserId,
      data?.media,
      data?.directUserIds,
      data?.readBy,
      data?.id
    );
  }

  static toObject(message: Message): any {
    const data = {
      authorId: message.authorId,
      message: message.message,
      date: message.date,
      trainingId: message.trainingId,
      trainingCenterId: message.trainingCenterId,
    };

    if (message.messageGroupId) {
      data['messageGroupId'] = message.messageGroupId;
    }

    if (message.toUserId) {
      data['toUserId'] = message.toUserId;
      data['directUserIds'] = [message.toUserId, message.authorId];
    }

    if (message.media) {
      data['media'] = message.media;
    }

    if (message.readBy) {
      data['readBy'] = message.readBy;
    }

    if (message.id) {
      data['id'] = message.id;
    }

    return data;
  }
}
