import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  provideAuth,
  getAuth,
  connectAuthEmulator,
  initializeAuth,
  indexedDBLocalPersistence,
  Auth,
} from '@angular/fire/auth';
import { provideFirestore, getFirestore, connectFirestoreEmulator } from '@angular/fire/firestore';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import {
  connectFunctionsEmulator,
  FunctionsModule,
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';

import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TutorMenuComponent } from './components/menus/tutor-menu/tutor-menu.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ApprenticeMenuComponent } from './components/menus/apprentice-menu/apprentice-menu.component';
import { Capacitor } from '@capacitor/core';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { VideoEditor } from '@awesome-cordova-plugins/video-editor/ngx';
import { AUTH_CONFIG_TOKEN, AUTH_PROVIDER_TOKEN, AuthConfig } from 'libs/auth/auth.config';
import { FirebaseAuthProvider } from 'libs/auth/providers/firebase-auth.provider';

registerLocaleData(localeFr);

export const AUTH_CONFIG: AuthConfig = {
  baseUrl: environment.url,
  bundleId: environment.bundleId,
  dynamicLinkDomain: environment.dynamicLinkDomain,
};

@NgModule({
  declarations: [AppComponent, TutorMenuComponent, ApprenticeMenuComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FunctionsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      let auth: Auth;
      if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
        // auth.settings.appVerificationDisabledForTesting = true;
      } else {
        auth = getAuth();
      }
      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://192.168.1.89:9099', { disableWarnings: true });
      }
      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (environment.useEmulators) {
        connectFirestoreEmulator(firestore, '192.168.1.89', 8080);
      }
      return firestore;
    }),
    provideMessaging(() => getMessaging()),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators) {
        connectStorageEmulator(storage, '192.168.1.89', 9199);
      }
      return storage;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      if (environment.useEmulators) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }
      return functions;
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: AUTH_CONFIG_TOKEN, useValue: AUTH_CONFIG },
    { provide: AUTH_PROVIDER_TOKEN, useClass: FirebaseAuthProvider },
    MediaCapture,
    VideoEditor,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
