import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from 'libs/user/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // console.log('url:', state.url);
    const roles = route?.data?.roles as Array<string>;

    return this.userService.authGuardUser$.pipe(
      map((user) => {
        // console.log('auth guard user:', user);
        if (!user && !state.url.includes('login')) {
          // console.log('navigate to login');
          this.router.navigateByUrl('login');
          return false;
        }

        if (user && roles && (!roles.includes(user.role) || state.url.includes('login'))) {
          // console.log('Auth Guard redirect to:', `${user.role}/home`);
          this.router.navigateByUrl(`${user.role}/home`);
          return false;
        }

        return true;
      })
    );
  }
}
