import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  filter,
  finalize,
  firstValueFrom,
  lastValueFrom,
  map,
  shareReplay,
  take,
  takeLast,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs';
import { MessageGroup } from './messageGroup.model';
import { FirebaseApiProvider } from 'libs/api/providers/firebase-api.provider';
import { Message } from './message.model';
import { UnsubscriberService } from 'libs/unsubcriber/unsubscriber.service';

import { environment } from 'apps/craf2s-client-app/src/environments/environment';
import { User } from 'libs/user/user.model';
import { Storage, deleteObject, ref } from '@angular/fire/storage';
import { WhereQuery } from 'libs/api/api.provider';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  userMessageGroupsRole: string;
  private messageGroupsSubject: BehaviorSubject<MessageGroup[]> = new BehaviorSubject([]);
  messageGroups$: Observable<MessageGroup[]> = this.messageGroupsSubject.asObservable();

  private messagesSubject: {
    [messengerId: string]: BehaviorSubject<Message[]>[];
  } = {};
  messages$: {
    [messengerId: string]: Observable<Message[]>[];
  } = {};

  private allMessagesSubject: {
    [messengerId: string]: BehaviorSubject<Message[]>;
  } = {};
  allMessages$: {
    [messengerId: string]: Observable<Message[]>;
  } = {};

  constructor(
    private apiService: FirebaseApiProvider,
    // CAN'T USE UPLOAD SERVICE ON ADMIN (NEED CORDOVA)
    // USE Fb Storage directly to delete medias (to refactor)
    private storage: Storage,
    private unsubscriberService: UnsubscriberService
  ) {}

  getUserMessageGroups(role: string): Observable<MessageGroup[]> {
    if (this.userMessageGroupsRole !== role) {
      this.userMessageGroupsRole = role;

      this.apiService
        .fetchAll<MessageGroup>('messageGroups', MessageGroup.fromObject, [
          {
            fieldPath: 'roles',
            condition: 'array-contains',
            value: role,
          },
        ])
        .then((messageGroups) => {
          console.log('getUserMessageGroups API FETCH:', role, messageGroups);
          this.messageGroupsSubject.next(messageGroups);
        });
    } else {
      console.log('getUserMessageGroups FROM CACHE:', role);
    }

    return this.messageGroups$.pipe(
      filter<MessageGroup[]>((messageGroups) => messageGroups.length > 0)
    );
  }

  listenToMessages(
    trainingId: string,
    messengerId: string,
    messageGroup: MessageGroup,
    toUser: User,
    fromUser: User,
    startAfterValue?: any
  ) {
    console.log('listenToMessages API CALL LISTENING:', trainingId, messengerId);

    if (!this.allMessagesSubject[messengerId]) {
      this.allMessagesSubject[messengerId] = new BehaviorSubject<Message[]>([]);
      this.allMessages$[messengerId] = this.allMessagesSubject[messengerId].asObservable();
    }

    if (!this.messagesSubject[messengerId]) {
      this.messagesSubject[messengerId] = [];
      this.messages$[messengerId] = [];
    }

    this.messagesSubject[messengerId].push(new BehaviorSubject<Message[]>([]));

    const listenerIndex = this.messagesSubject[messengerId].length - 1;

    this.messages$[messengerId].push(
      this.messagesSubject[messengerId][listenerIndex].asObservable()
    );

    const whereQ: WhereQuery[] = [];
    if (trainingId) {
      whereQ.push({
        fieldPath: 'trainingId',
        condition: '==',
        value: trainingId,
      });
    }

    if (messageGroup) {
      whereQ.push({
        fieldPath: 'messageGroupId',
        condition: '==',
        value: messengerId,
      });
    } else {
      whereQ.push({
        fieldPath: 'directUserIds',
        condition: 'in',
        value: [
          [toUser.id, fromUser.id],
          [fromUser.id, toUser.id],
        ],
      });
    }

    const messagesSubscriber = this.apiService
      .listenToChanges<Message>(
        'messages',
        Message.fromObject,
        whereQ,
        'date',
        'desc',
        environment.limitMessagesCount,
        startAfterValue
      )
      .pipe(
        finalize(() => {
          delete this.messagesSubject[messengerId];
          delete this.messages$[messengerId];
          delete this.allMessagesSubject[messengerId];
          delete this.allMessages$[messengerId];
          console.log(`listenToMessages ${messengerId} ${listenerIndex} finalized.`);
        })
      )
      .subscribe((messages) => {
        const withAuthorMessages = messages.map((message) => {
          message.author =
            messageGroup?.users?.find((user) => user.id === message.authorId) ?? fromUser;
          return message;
        });

        console.log(`listenToMessages ${messengerId} ${listenerIndex} NEXT:`, withAuthorMessages);
        this.messagesSubject[messengerId][listenerIndex].next(withAuthorMessages);
        this.allMessagesSubject[messengerId].next(
          this.messagesSubject[messengerId].reduce(
            (allMessages, subject) => [...allMessages, ...subject.getValue()],
            []
          )
        );
      });

    this.unsubscriberService.add(
      `MessageService:listenToMessages:${messengerId}`,
      messagesSubscriber
    );

    return this.allMessages$[messengerId];
  }

  async sendNewMessage(message: Message): Promise<Message> {
    try {
      const data = Message.toObject(message);
      message.id = await this.apiService.create<Message>('messages', data, Message.toObject);
      return message;
    } catch (e) {
      throw e;
    }
  }

  async deleteMessage(messageId: string) {
    try {
      // console.log('deleteMessage messageId:', messageId);
      await this.apiService.delete('messages', messageId);
    } catch (e) {
      throw e;
    }
  }

  async setMessagesReadBy(messages: Message[], userId: string): Promise<void> {
    try {
      const data = messages.map((message) => {
        if (!message.readBy) {
          message.readBy = [];
        }
        message.readBy.push(userId);
        return message;
      });
      // console.log('Set Messages Read By: ', data);
      this.apiService.updateBatch<Message>('messages', data, Message.toObject);
    } catch (e) {
      throw e;
    }
  }

  async deleteAllTrainingMessages(trainingId: string) {
    try {
      const trainingMessages = await this.apiService.fetchAll<Message>(
        'messages',
        Message.fromObject,
        [
          {
            fieldPath: 'trainingId',
            condition: '==',
            value: trainingId,
          },
        ]
      );

      console.log('Messages to delete:', trainingMessages);

      await this.deleteMessages(trainingMessages, true);
    } catch (e) {
      throw e;
    }
  }

  async deleteTrainingUserMessages(userId: string, trainingId: string) {
    try {
      const fromUserMessages = await this.apiService.fetchAll<Message>(
        'messages',
        Message.fromObject,
        [
          {
            fieldPath: 'authorId',
            condition: '==',
            value: userId,
          },
          {
            fieldPath: 'trainingId',
            condition: '==',
            value: trainingId,
          },
        ]
      );

      const toUserMessages = await this.apiService.fetchAll<Message>(
        'messages',
        Message.fromObject,
        [
          {
            fieldPath: 'toUserId',
            condition: '==',
            value: userId,
          },
          {
            fieldPath: 'trainingId',
            condition: '==',
            value: trainingId,
          },
        ]
      );

      const messagesToDelete = [...(fromUserMessages ?? []), ...(toUserMessages ?? [])];

      console.log('Messages to delete:', messagesToDelete);

      await this.deleteMessages(messagesToDelete, true);
    } catch (e) {
      throw e;
    }
  }

  async deleteTutorApprenticeMessages(
    tutorId: string,
    apprenticeId: string,
    trainingId: string,
    noOtherApprentices: boolean
  ): Promise<void> {
    try {
      const tripartieId = await firstValueFrom(
        this.getUserMessageGroups('tutor').pipe(
          tap((mgs) => console.log(mgs)),
          map(
            (messageGroups) =>
              messageGroups.filter(
                (messageGroup) =>
                  JSON.stringify(messageGroup.singleRoles) ===
                  JSON.stringify(['apprentice', 'tutor'])
              )[0].id
          ),
          tap((id) => console.log(id))
        )
      );

      const tripartiteMessageGroupId = `${tripartieId}/${apprenticeId}/${tutorId}`;

      const tripartiteMessages = await this.apiService.fetchAll<Message>(
        'messages',
        Message.fromObject,
        [
          {
            fieldPath: 'messageGroupId',
            condition: '==',
            value: tripartiteMessageGroupId,
          },
          {
            fieldPath: 'trainingId',
            condition: '==',
            value: trainingId,
          },
        ]
      );

      const tutorApprenticeMessages = await this.apiService.fetchAll<Message>(
        'messages',
        Message.fromObject,
        [
          {
            fieldPath: 'authorId',
            condition: 'in',
            value: [tutorId, apprenticeId],
          },
          {
            fieldPath: 'toUserId',
            condition: 'in',
            value: [tutorId, apprenticeId],
          },
          {
            fieldPath: 'trainingId',
            condition: '==',
            value: trainingId,
          },
        ]
      );

      const messagesToDelete = [...(tripartiteMessages ?? []), ...(tutorApprenticeMessages ?? [])];

      if (noOtherApprentices) {
        const messageGroupsMessages = await this.apiService.fetchAll<Message>(
          'messages',
          Message.fromObject,
          [
            {
              fieldPath: 'messageGroupId',
              condition: '!=',
              value: null,
            },
            {
              fieldPath: 'authorId',
              condition: '==',
              value: tutorId,
            },
            {
              fieldPath: 'trainingId',
              condition: '==',
              value: trainingId,
            },
          ]
        );

        messagesToDelete.push(...(messageGroupsMessages ?? []));
      }

      console.log('Messages to delete:', messagesToDelete);

      await this.deleteMessages(messagesToDelete, true);
    } catch (e) {
      throw e;
    }
  }

  private async deleteMessages(messages: Message[], withMedia = false) {
    try {
      if (withMedia === true) {
        const messagesWithMedia = messages.filter((message) => message.media !== undefined);
        await Promise.all(
          messagesWithMedia.map(async (message) => {
            console.log('Delete media:', message.media);
            const fileRef = ref(this.storage, message.media);
            try {
              await deleteObject(fileRef);
            } catch (e) {
              console.log(e);
            }
          })
        );
      }
      const ids = Array.from(new Set(messages.map((n) => n.id)));
      console.log('Delete Messages: ', ids);
      await this.apiService.deleteBatch('messages', ids);
    } catch (e) {
      throw e;
    }
  }
}
